import { Module,VuexModule,getModule,Mutation,Action } from "vuex-module-decorators"
import store from "@/store"
import SearchFriendStoreType from './indexType'
import {FindFriendsType} from "@/Api/Message/indexType";

@Module({
    name:"SearchFriend",
    store,
    dynamic: true,
})
export default class SearchFriend extends VuexModule implements SearchFriendStoreType{

    SearchFriendList: any[] = [];
    get getSearchFriendList(){
        return this.SearchFriendList
    }
    @Mutation
    SetSearchFriendList(data: any[]): void {
        this.SearchFriendList = data
    }

    SearchFriendUpData: FindFriendsType = {
        pageNo: 1, pageSize: 15, phones: ""
    };
    get getSearchFriendUpData(){
        return this.SearchFriendUpData
    }
    @Mutation
    SetSearchFriendUpData(data: FindFriendsType): void {
        this.SearchFriendUpData = data
    }

}

export const SearchFriendStore = getModule( SearchFriend )
