import { FindFriends } from '@/Api/Message';
import Storage from "@/util/Storage"
import {SearchFriendStore} from "@/views/Friend/SearchFriend/Store";

// 搜索朋友
export function searchFriends() {
    const userId = Storage.GetData_?.("userId")
    const token = Storage.GetData_?.("token")
    return Promise.resolve(FindFriends({userId,token},SearchFriendStore.getSearchFriendUpData).then(res=>{
        return res.data || []
    }))
}
